<template>
    <form
        class="form"
        novalidate="novalidate"
        id="st_citizen_application_form"
    >
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.personal_numeric_code.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.personal_numeric_code.name"
                            :name="fields.personal_numeric_code.name"
                            v-model="model[fields.personal_numeric_code.name]"
                            set-validation
                            :max-length="13"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.first_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.first_name.name"
                            :name="fields.first_name.name"
                            v-model="model[fields.first_name.name]"
                            allow-only-letters
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.last_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.last_name.name"
                            :name="fields.last_name.name"
                            v-model="model[fields.last_name.name]"
                            allow-only-letters
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.id_card_serial.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.id_card_serial.name"
                            :name="fields.id_card_serial.name"
                            :disabled="viewMode"
                            v-model="model[fields.id_card_serial.name]"
                            allow-only-alphanumeric
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.id_card_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.id_card_number.name"
                            :name="fields.id_card_number.name"
                            :disabled="viewMode"
                            v-model="model[fields.id_card_number.name]"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.county_id.label }} *
                    </label>
                    <st-autocomplete
                        v-model="model[fields.county_id.name]"
                        :options="fields.county_id.options"
                        :ref="fields.county_id.name"
                        :name="fields.county_id.name"
                        :disabled="viewMode"
                        :fields="fields"
                        :formValidation="fv"
                    >
                    </st-autocomplete>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.locality_id.label }} *
                    </label>
                    <div label="">
                        <st-autocomplete
                            v-model="model[fields.locality_id.name]"
                            :options="fields.locality_id.options"
                            :ref="fields.locality_id.name"
                            :name="fields.locality_id.name"
                            :disabled="!fields.locality_id.options.length || viewMode"
                            :model="model"
                            :fields="fields"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.village_id.label }}
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.village_id.name]"
                            :options="fields.village_id.options"
                            :ref="fields.village_id.name"
                            :name="fields.village_id.name"
                            :disabled="!fields.village_id.options.length || viewMode"
                            :model="model"
                            :fields="fields"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.sector.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.sector.name"
                            :name="fields.sector.name"
                            :disabled="viewMode"
                            v-model="model[fields.sector.name]"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.street.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.street.name"
                            :name="fields.street.name"
                            :disabled="viewMode"
                            v-model="model[fields.street.name]"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.street_number.label }} *
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.street_number.name"
                                    :name="fields.street_number.name"
                                    :disabled="viewMode"
                                    v-model="model[fields.street_number.name]"
                                    allow-only-alphanumeric
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.staircase.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.staircase.name"
                                    :name="fields.staircase.name"
                                    :disabled="viewMode"
                                    v-model="model[fields.staircase.name]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.block.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.block.name"
                                    :name="fields.block.name"
                                    :disabled="viewMode"
                                    v-model="model[fields.block.name]"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.floor.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.floor.name"
                                    :name="fields.floor.name"
                                    :disabled="viewMode"
                                    v-model="model[fields.floor.name]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.apartment.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.apartment.name"
                                    :name="fields.apartment.name"
                                    :disabled="viewMode"
                                    v-model="model[fields.apartment.name]"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.postal_code.label }} *
                            </label>
                            <div>
                                <st-input-text
                                    v-model="model[fields.postal_code.name]"
                                    :ref="fields.postal_code.name"
                                    :disabled="viewMode"
                                    :name="fields.postal_code.name"
                                    set-validation
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.email.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.email.name"
                            :name="fields.email.name"
                            :disabled="viewMode"
                            v-model="model[fields.email.name]"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.phone_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.phone_number.name"
                            :name="fields.phone_number.name"
                            :disabled="viewMode"
                            v-model="model[fields.phone_number.name]"
                            phone-validation
                        />
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="showUseProfileData">
                <b-form-checkbox
                    v-model="model[fields.use_profile_data.name]"
                    size="lg"
                    :disabled="viewMode"
                    @change="onChangeUseMyProfileData"
                >
                    {{ fields.use_profile_data.label }}
                </b-form-checkbox>
            </div>
        </div>
    </form>
</template>

<script>
import { BeneficiaryModel } from "@/modules/beneficiaries/models/beneficiary-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters } from "vuex";

const { fields } = BeneficiaryModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.personal_numeric_code,
    fields.first_name,
    fields.last_name,
    fields.id_card_serial,
    fields.id_card_number,
    fields.email,
    fields.phone_number,
    fields.county_id,
    fields.locality_id,
    fields.village_id,
    fields.sector,
    fields.street,
    fields.street_number,
    fields.postal_code,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
    fields.use_profile_data,
]);

export default {
    name: "CitizenBeneficiaryForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object
        }
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            loading: "shared/loading",
            record: "applications/form/record",
        }),
        useMyProfileData() {
            return this.model.use_profile_data;
        },
        showUseProfileData() {
            return this.currentUser.user_type_name === 'citizen';
        }
    },
    methods: {
        onChangeUseMyProfileData() {
            if (this.useMyProfileData) {
                this.populateUserDetailsWithCurrentUser();
                return;
            }
            this.clearUserDetails();
        },
        populateUserDetailsWithCurrentUser() {
            const userDetails = this.getInitialModelValues();
            const localityId = userDetails.locality_id;
            const values = { ...this.model, ...userDetails };
            this.model = values;
            this.$nextTick(()=>{
                this.model.locality_id = localityId;
            })
        },
        clearUserDetails() {
            this.model = {
                use_profile_data: false,
                county_id: null,
                locality_id: null,
                village_id: null,
                user_type_name: this.model.user_type_name
            };

            if (this.fv.fields.user_type_name)  this.fv.removeField('user_type_name');
            this.fv.resetForm(true);
        },
        getInitialModelValues() {
            const userType = {
                user_type_name: 'citizen',
                use_profile_data: this.showUseProfileData
            }

            if (this.item) {
                return { ...this.item, ...userType} ;
            }

            if (this.showUseProfileData) {
                const user = Object.assign({}, this.currentUser);
                delete user.id;
                return { ...user, ...userType};
            }

            return userType;
        }
    },
    async created() {
        const record = this.getInitialModelValues();
        this.model =  formSchema.initialValues(record);
    },
    mounted() {
        this.fv = createFormValidation('st_citizen_application_form', this.rules);
    },
    watch: {
        item: {
            deep: true,
            handler(item) {
                this.model = item;
                this.model[fields.use_profile_data.name] = false;
            }
        }
    }
}
</script>
