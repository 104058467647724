<template>
    <div v-can:create="'beneficiaries'">
         <st-button :callback="openAddBeneficiaryModal" size="large" variant="secondary">
                        <span class="d-flex align-items-center">
                            <i class="fas fa-plus"></i>
                            {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.ADD_BTN") }}
                        </span>
                    </st-button>

        <beneficiary-modal
                ref="addBeneficiaryModal"
                :item="selectedEntity"
                :items="beneficiaries"
                @processBeneficiary="processBeneficiary"
                :addMode="addMode"
                @cancel="cancel"
            ></beneficiary-modal>

      
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StButton from '../../../shared/components/StButton.vue';
import  BeneficiaryModal from "@/modules/beneficiaries/components/BeneficiaryModal";
import { BeneficiaryModel } from "@/modules/beneficiaries/models/beneficiary-model";

export default {
    components: { 
        StButton, 
        BeneficiaryModal 
    },
    name: 'BeneficiariesToolbar',
    props: {
        addMode: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
          processBeneficiary(data) {
            const isEdit = Object.keys(data).some((el) => el === 'index');
            if (isEdit) {
                Vue.set(this.items, data.index, data.beneficiaryDetails);
            } else {
                this.items.push({
                    ...data.beneficiaryDetails,
                    index: this.items.length
                });
            }

            this.selectedEntity = null;
        },
        openAddBeneficiaryModal() {
            this.$refs.addBeneficiaryModal.show();
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
    },
    data() {
        return {
            presenter: BeneficiaryModel.presenter,
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
        }
    },
     computed: {
        ...mapGetters({
            beneficiaries: 'beneficiaries/list/beneficiaries',
            permissions: 'beneficiaries/list/permissions',
        })
    },
}
</script>