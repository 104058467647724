<template>
    <form class="form"
        novalidate="novalidate"
        id="st_foreigner_form"
    >
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.passport_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.passport_number.name]"
                            :ref="fields.passport_number.name"
                            :name="fields.passport_number.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.last_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.last_name.name]"
                            :ref="fields.last_name.name"
                            :name="fields.last_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.first_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.first_name.name]"
                            :ref="fields.first_name.name"
                            :name="fields.first_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.COUNTRY') }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.country.name]"
                            :options="fields.country.options"
                            :ref="fields.country.name"
                            :name="fields.country.name"
                            value-field="text"
                            text-field="text"
                             @change="onChange(fields.country)"
                             :disabled="viewMode"
                        ></st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.city.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.city.name]"
                            :ref="fields.city.name"
                            :name="fields.city.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.address.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.address.name]"
                            :ref="fields.address.name"
                            :name="fields.address.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.email.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.email.name]"
                            :ref="fields.email.name"
                            :name="fields.email.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.phone_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.phone_number.name]"
                            :ref="fields.phone_number.name"
                            :name="fields.phone_number.name"
                            :disabled="viewMode"
                            phone-validation
                        />
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="showUseProfileData">
                <b-form-checkbox
                    v-model="model[fields.use_profile_data.name]"
                    size="lg"
                    @change="onChangeUseMyProfileData"
                    :disabled="viewMode"
                >
                    {{ fields.use_profile_data.label }}
                </b-form-checkbox>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { BeneficiaryModel } from "@/modules/applications/models/beneficiary-model";
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
const { fields } = BeneficiaryModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.passport_number,
    fields.first_name,
    fields.last_name,
    fields.country,
    fields.city,
    fields.address,
    fields.email,
    fields.phone_number,
    fields.use_profile_data,
]);

export default {
    name: 'ForeignerBeneficiaryForm',
    components: {},
    props: {
        viewMode: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object
        }
    },
    data() {
        return {
            fields: fields,
            model: null,
            rules: formSchema.rules(),
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            localities: 'profile/settlements',
            villages: 'profile/villages',
            record: "applications/form/record"
        }),
        useMyProfileData() {
            return this.model.use_profile_data;
        },
        showUseProfileData() {
            return this.currentUser.user_type_name === 'foreigner';
        }
    },
    async created() {
        const record = this.getInitialModelValues();
        if (this.viewMode) {
            this.model = formSchema.initialValues(record);
        } else {
            this.model = formSchema.initialValues(record);
            record.use_profile_data = true;
            this.model.user_type_name = 'foreigner';
        }
    },
    mounted() {
        this.fv = createFormValidation('st_foreigner_form', this.rules);
    },
    methods: {
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
        onChangeUseMyProfileData() {
            if (this.useMyProfileData) {
                this.populateUserDetailsWithCurrentUser();
                return;
            }

            this.clearUserDetails();
        },
        populateUserDetailsWithCurrentUser() {
            const userDetails = this.getInitialModelValues();

            const values = {...this.model, ...userDetails};
            this.model = values;
        },
        clearUserDetails() {
            this.model = {
                use_profile_data: false
            };
        },
        getInitialModelValues() {
            const userType = {
                user_type_name: 'foreigner'
            }

            if ((this.viewMode || (this.record?.status === 'draft')) && this.item) {
                return {
                    ...this.item,
                };
            } else {
                if (this.item) {
                    return { ...this.item, ...userType};
                }

                if (this.showUseProfileData) {
                    return {
                        ...this.currentUser, ...userType, ...{use_profile_data: true} 
                    };
                }

                return userType;
            }
        },
    }
}
</script>
