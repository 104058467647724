<template>
    <div class="form-group" id="st_beneficiary_section">
        <BeneficiaryFormTypes
            ref="beneficiary"
            :viewMode="viewMode"
            :editMode="editMode"
            :item="selectedEntity"
            @submit="submit"
            @cancel="cancel"
        >
            <template #buttons="{ submit, cancel }">
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="cancel">
                        {{ $t('APPLICATION.MODALS.CANCEL') }}
                    </st-button>
                    <st-button size="large" :callback="submit">
                          {{ submitButtonText }}
                    </st-button>
                </div>
            </template>
        </BeneficiaryFormTypes>
    </div>
</template>

<script>
import BeneficiaryFormTypes from "./BeneficiaryFormTypes";
import { mapActions, mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: "BeneficiaryForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            selectedEntity: null
        };
    },
    components: {
        BeneficiaryFormTypes,
    },
    computed: {
        ...mapGetters({
            record: "beneficiaries/form/record",
        }),
        submitButtonText() {
            return this.item ? this.$t('APPLICATION.MODALS.SAVE') : this.$t('APPLICATION.MODALS.ADD');
        },
    },
    methods: {
        ...mapActions({
            doAddBeneficiary: "beneficiaries/form/addBeneficiary",
            doUpdateBeneficiary: "beneficiaries/form/updateBeneficiary",
        }),
        onModelChange(model) {
            this.$emit("change", model);
        },
        async validateData() {
            const fieldRefs = this.$refs.beneficiary.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key]
            );
            const selectedEntity = fieldRefs[selectedEntityKey];

            let status = await selectedEntity.fv.validate();
            let details = this.fillBeneficiaryModel(selectedEntity);

            const beneficiary_id = this.item?.beneficiary_id || generateRandomId();
            const beneficiaryDetails = { ...selectedEntity.model, ...details, beneficiary_id };
           
            return {
                status,
                beneficiaryDetails,
            };
        },
        fillBeneficiaryModel(entity) {
            if (entity.model.user_type_name === "foreigner") {
                return {};
            }

            let details = {
                locality: entity.fields.locality_id.options.find((el) => el.value === entity.model.locality_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
                village: entity.fields.village_id?.options.find((el) => el.value === entity.model.village_id)?.text || "",
            };

            return details;
        },
        async submit(data) {
            let beneficiary = await this.validateData();
            if (beneficiary.status === 'Valid') {
                if (this.item) {
                    beneficiary.index = this.item.index;
                }

                if (!data) {
                    beneficiary.beneficiaryDetails = await this.doAddBeneficiary(beneficiary.beneficiaryDetails);
                } else {
                    beneficiary.beneficiaryDetails = await this.doUpdateBeneficiary(beneficiary.beneficiaryDetails);
                }

                this.$emit("submitForm", beneficiary);
            }
        },
        cancel() {
            this.$emit("cancelForm");
        }
    },
    created() {
        this.selectedEntity = this.item || null;
        this.editMode = this.item ? true : false;
    },
};
</script>
