<template>
    <div>
        <Loader v-if="isLoading"/>
        <st-page
            :title="$t('BENEFICIARIES.TITLE')"
        >
            <template #toolbar>
                <beneficiaries-toolbar/>
            </template>
            <beneficiaries-filter>
                <beneficiaries-table />
            </beneficiaries-filter>
        </st-page>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BeneficiariesToolbar from '@/modules/beneficiaries/components/BeneficiariesToolbar.vue';
import BeneficiariesFilter from '../components/BeneficiariesFilter.vue';
import BeneficiariesTable from '@/modules/beneficiaries/components/BeneficiariesTable.vue';

export default {
    components: {
        BeneficiariesToolbar,
        BeneficiariesFilter,
        BeneficiariesTable,
    },
    created() {},
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            beneficiaries: 'beneficiaries/list/beneficiaries',
            permissions: 'beneficiaries/list/permissions',
        }),
        loadingBeneficiaries() {
            return this.loading['beneficiaries/getBeneficiaries'];
        },
        loadingSelectAccount() {
            return this.loading['beneficiaries/selectAccount'];
        },
        isLoading() {
            return this.loadingBeneficiaries || this.loadingSelectAccount;
        },
    },
    methods: {
        ...mapActions({
        }),
    },
}
</script>
